<template>
  <div class="page-container message-template-form-page">
    <portal to="page-name">Modelos de mensagem</portal>

    <div class="card card-page">
      <div class="card-header">
        <div
          v-if="statusName"
          class="float-right label label-rounded"
          :class="statusLabelClass"
        >{{ statusName }}</div>
        <h1 class="card-title">Cadastro de modelo de mensagem</h1>
      </div>

      <div class="card-body">
        <div class="form-group">
          <label
            class="form-radio form-inline"
            v-for="channel in channels"
            :key="channel.key"
          >
            <input
              type="radio"
              name="channel"
              :value="channel.key"
              v-model="form.channel"
              :disabled="!channel.active || !!form.id"
            ><i class="form-icon"></i> {{ channel.name }}
          </label>
        </div>

        <div class="columns">
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.type.$error}"
          >
            <label for="type" class="form-label">Tipo</label>
            <select
              id="type"
              class="form-select"
              :class="{ readonly: !canEdit }"
              v-model="form.type"
              :disabled="!canEdit"
            >
              <option value="">[Selecione]</option>
              <option
                v-for="(text, value) in types"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
            <template v-if="$v.form.type.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.type.required"
              >Campo obrigatório</div>
            </template>
          </div>

          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.category.$error}"
          >
            <label for="category" class="form-label">Categoria</label>
            <select
              id="category"
              class="form-select"
              :class="{ readonly: !canEdit }"
              v-model="form.category"
              :disabled="!canEdit"
            >
              <option value="">[Selecione]</option>
              <option
                v-for="(text, value) in categories"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
            <template v-if="$v.form.category.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.category.required"
              >Campo obrigatório</div>
            </template>
          </div>

          <div
            class="column col-12 form-group"
            :class="{'has-error': $v.form.header.$error}"
          >
            <label
              for="header"
              class="form-label"
            >Titulo <small v-if="isWhatsapp">(opcional)</small></label>
            <input
              type="text"
              id="header"
              class="form-input"
              v-model="form.header"
              :readonly="!canEdit"
            >
            <template v-if="$v.form.header.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.header.required"
              >Campo obrigatório</div>
            </template>
          </div>

          <div
            class="column col-12 form-group"
            :class="{'has-error': $v.form.body.$error}"
          >
            <label for="body" class="form-label">Conteúdo</label>
            <textarea
              id="body"
              v-model="form.body"
              class="form-input"
              ROWS="7"
              @change="$v.form.body.$touch()"
              :readonly="!canEdit"
            ></textarea>
            <template v-if="$v.form.body.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.body.required"
              >Campo obrigatório</div>
            </template>
          </div>

          <div class="column col-12 form-group">
            <label for="footer" class="form-label">Rodapé <small>(opcional)</small></label>
            <input
              type="text"
              id="footer"
              class="form-input"
              v-model="form.footer"
              :readonly="!canEdit"
            >
          </div>

          <template v-if="isWhatsapp">
            <div class="column col-5 form-group">
              <label
                for="button"
                class="form-label"
              >Botões <small>(recomendado até 2 botões)</small></label>
              <div class="input-group">
                <input
                  type="text"
                  id="button"
                  class="form-input"
                  v-model="button"
                  :readonly="!canEdit"
                >
                <button
                  class="btn btn-primary input-group-btn"
                  @click="addButton"
                  :disabled="!canEdit || !button"
                >Adicionar</button>
              </div>
            </div>

            <div class="column col-7 form-group">
              <label class="form-label">&nbsp;</label>
              <button
                class="btn btn-success mr-1"
                v-for="(button, idx) in form.buttons"
                :key="idx"
                @click="removeButton(idx)"
              >{{ button }}</button>
            </div>
          </template>
        </div>
      </div>

      <div class="card-footer">
        <div class="columns">
          <div class="column col-6">
            <button
              v-if="canEdit"
              class="btn btn-primary mr-1"
              @click="save()"
              :disabled="saving"
              :class="{ loading: saving }"
            >Salvar</button>
            <button class="btn" @click="$router.back()">Voltar</button>
          </div>
          <div class="column col-6 text-right">
            <button
              v-if="form.id"
              class="btn btn-error"
              @click="remove()"
              :disabled="saving"
              :class="{ loading: saving }"
            >Excluir</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/src/validators';
import formMixin from '@/mixins/form';
import { mergeFrom } from '@/helpers/object';
import { types, templateCategoryMapping } from '@/data/message-types';
import { categories } from '@/data/message-template-categories';
import { getName as getStatusName } from '@/data/message-template-statuses';
import { channels, WHATSAPP, EMAIL } from '@/data/message-channels';
import { statusLabelClass } from './helpers';

export default {
  mixins: [formMixin],
  data() {
    return {
      isNew: true,
      loading: false,
      saving: false,
      form: this.blankForm(),
      button: '',
      types,
      categories,
    };
  },
  validations() {
    const form = {
      type: { required },
      category: { required },
      body: { required },
      header: {},
    };

    if (this.isEmail) {
      form.header = { required };
    }

    return { form };
  },
  mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (this.isNew) {
      this.form.channel = this.channels.find(({ active }) => active).key;
    } else {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  watch: {
    'form.type': function formType(val) {
      if (!this.form.category) {
        this.form.category = templateCategoryMapping[val] || '';
      }
    },
  },
  computed: {
    ...mapGetters(['branchSettings']),
    isWhatsappEnabled() {
      return this.branchSettings && this.branchSettings.whatsapp.enabled;
    },
    channels() {
      return Object.keys(channels).map(key => ({
        key,
        name: channels[key],
        active: key !== WHATSAPP || this.isWhatsappEnabled,
      }));
    },
    isEmail() {
      return this.form.channel === EMAIL;
    },
    isWhatsapp() {
      return this.form.channel === WHATSAPP;
    },
    canEdit() {
      return this.isNew || !this.isWhatsapp;
    },
    statusName() {
      return getStatusName(this.form.status);
    },
    statusLabelClass() {
      return statusLabelClass(this.form.status);
    },
  },
  methods: {
    async load() {
      this.loading = true;

      try {
        const { data } = await this.$http.get(`/message-templates/${this.form.id}`);
        this.form = mergeFrom(this.blankForm(), data);
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
    },
    async save() {
      if (!this.canEdit) return;

      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.saving = true;

      const formData = this.clone(this.form);
      delete formData.id;

      const request = this.isNew
        ? this.$http.post('/message-templates', formData)
        : this.$http.put(`/message-templates/${this.form.id}`, formData);

      try {
        const { data } = await request;
        if (this.isNew) {
          await this.$router.replace(`/message-templates/${data.id}/edit`);
          this.isNew = false;
          this.form.id = data.id;
        }
        this.$toast.show('Registro salvo');
      } catch (e) {
        this.$toast.error(e);
      }

      this.saving = false;
    },
    async remove() {
      this.saving = true;

      try {
        await this.$http.delete(`/message-templates/${this.form.id}`);
        await this.$router.push('/message-templates');
      } catch (e) {
        this.$toast.error(e);
      }

      this.saving = false;
    },
    addButton() {
      if (this.canEdit) {
        this.form.buttons.push(this.button);
        this.button = '';
      }
    },
    removeButton(idx) {
      if (this.canEdit) {
        this.form.buttons.splice(idx, 1);
      }
    },
    blankForm() {
      return {
        id: '',
        channel: '',
        type: '',
        category: '',
        header: '',
        body: '',
        footer: '',
        buttons: [],
        status: '',
      };
    },
  },
};
</script>

<style lang="scss">
</style>
