import * as templateStatuses from '@/data/message-template-statuses';

export function statusLabelClass(status) {
  switch (status) {
    case templateStatuses.APPROVED: return 'label-success';
    case templateStatuses.REJECTED: return 'label-error';
    case templateStatuses.PENDING: return 'label-warning';
    default: return '';
  }
}

export default {
  statusLabelClass,
};
