<template>
  <div class="message-list">
    <div class="columns">
      <div class="column form-group">
        <label class="form-label">Período</label>
        <select class="form-select" v-model="period" @change="setPeriod">
          <option
            v-for="option in periodOptions"
            :value="option.key"
            :key="option.key"
          >{{ option.text }}</option>
        </select>
      </div>
      <div class="column form-group">
        <label for="type" class="form-label">Tipo</label>
        <select id="type" class="form-select" v-model="filter.type">
          <option value="">Todos os tipos</option>
          <option
            v-for="(text, value) in types"
            :value="value"
            :key="value"
          >{{ text }}</option>
        </select>
      </div>
      <div class="column form-group">
        <label for="channel" class="form-label">Canal</label>
        <select id="channel" class="form-select" v-model="filter.channel">
          <option value="">Todos os canais</option>
          <option
            v-for="(text, value) in channels"
            :value="value"
            :key="value"
          >{{ text }}</option>
        </select>
      </div>
      <div class="column form-group">
        <label for="status" class="form-label">Status</label>
        <select id="status" class="form-select" v-model="filter.status">
          <option value="">Todos os status</option>
          <option
            v-for="(text, value) in statuses"
            :value="value"
            :key="value"
          >{{ text }}</option>
        </select>
      </div>
      <div class="column col-auto">
        <label class="form-label">&nbsp;</label>
        <button
          class="btn btn-primary btn-icon btn-icon-left"
          type="button"
          @click="search"
        ><fa-icon :icon="['fal', 'search']"/> Filtrar</button>
      </div>
    </div>

    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <template v-if="data.items.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Templates</p>
          <p class="empty-subtitle">
            Nenhum template encontrado. Verifique os filtros para realizar novas buscas
          </p>
        </div>
      </template>
      <template v-else>
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th class="hide-sm" style="width:40px">#</th>
            <th>Data</th>
            <th>Tipo</th>
            <th>Contato</th>
            <th>Canal</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>{{ item.date | date('datetime') }}</td>
              <td>{{ item.typeName }}</td>
              <td>{{ item.contact.value }} - {{ item.contact.name }}</td>
              <td>{{ item.channelName }}</td>
              <td>
                <span
                  class="label label-rounded"
                  :class="item.statusLabelClass"
                >{{ item.statusName }}</span>
              </td>
              <td class="text-right">
                <button
                  @click="showDetails(item)"
                  class="btn btn-sm btn-action btn-icon btn-gray tooltip"
                  data-tooltip="Detalhar"
                ><fa-icon :icon="['fal', 'eye']"></fa-icon></button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </template>

    <div class="text-center pt-2 mt-2" v-if="data.hasMore && !loading">
      <button
        class="btn btn-primary btn-fixed-width"
        @click="loadMore"
        :disabled="loadingMore"
        :class="{ loading: loadingMore }"
      >Carregar mais</button>
    </div>

    <dx-modal
      v-if="modalDetails.show"
      v-model="modalDetails.show"
      title="Detalhe da mensagem"
      id="message-detail"
    >
      <div class="mb-1">
        Contato:
        <strong class="text-info">
          {{ modalDetails.data.contact.value }}
          - {{ modalDetails.data.contact.name }}
        </strong>
      </div>

      <div class="message-detail-content">
        <div
          v-if="modalDetails.data.header"
          class="message-detail-header"
        >{{ modalDetails.data.header }}</div>
        <div
          v-if="modalDetails.data.body"
          class="message-detail-body"
        >{{ modalDetails.data.body }}</div>
        <div
          v-if="modalDetails.data.footer"
          class="message-detail-footer"
        >{{ modalDetails.data.footer }}</div>
      </div>

      <div class="columns mb-1">
        <div class="column col-6">
          Tipo: <strong>{{ modalDetails.data.typeName }}</strong>
        </div>
        <div class="column col-6">
          Canal: <strong>{{ modalDetails.data.channelName }}</strong>
        </div>
        <div class="column col-6 pt-2">
          Data: <strong>{{ modalDetails.data.date | date('datetime') }}</strong>
        </div>
        <div class="column col-6 pt-2">
          Status: <strong>{{ modalDetails.data.statusName }}</strong>
        </div>
      </div>
    </dx-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { types, getName as getTypeName } from '@/data/message-types';
import { channels, getName as getChannelName } from '@/data/message-channels';
import { statuses, getName as getStatusName } from '@/data/message-statuses';
import { messageStatusLabelClass as statusLabelClass } from './helpers';

export default {
  data() {
    return {
      debounceTimeout: null,
      loading: false,
      period: 'month',
      periodOptions: [],
      filter: {
        type: '',
        channel: '',
        category: '',
        status: '',
        startDate: '',
        endDate: '',
      },
      loadingMore: false,
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
        hasMore: false,
      },
      modalDetails: {
        show: false,
        data: null,
      },
      types,
      channels,
      statuses,
    };
  },
  // watch: {
  //   filter: {
  //     handler() {
  //       clearTimeout(this.debounceTimeout);
  //       this.debounceTimeout = setTimeout(this.search, 600);
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.makePeriodOptions();
      this.setPeriod();
      this.load();
    },
    makePeriodOptions() {
      const add = (startDate, endDate, text, key) => {
        this.periodOptions.push({
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          key: key || startDate.format('YYYY_MM_DD'),
          text,
        });
      };

      const date = moment();

      add(date, date, 'Hoje', 'today');
      add(date.clone().startOf('week'), date, 'Esta semana', 'week');
      add(date.clone().startOf('month'), date, 'Este mês', 'month');

      for (let i = 0; i < 6; i += 1) {
        date.subtract(1, 'month').startOf('month');
        add(date, date.clone().endOf('month'), date.format('MM/YYYY'));
      }
    },
    setPeriod() {
      const defaultPeriod = this.periodOptions.find(({ key }) => key === this.period);
      this.filter.startDate = defaultPeriod.startDate;
      this.filter.endDate = defaultPeriod.endDate;
    },
    search() {
      this.data.offset = 0;
      this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    async load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          params[key] = this.filter[key];
        }
      });

      try {
        const { data } = await this.$http.get('/messages', { params });

        data.items = data.items.map((item) => {
          item.channelName = getChannelName(item.channel);
          item.typeName = getTypeName(item.type);
          item.statusName = getStatusName(item.status);
          item.statusLabelClass = statusLabelClass(item.status);
          return item;
        });

        this.data.hasMore = data.hasMore;
        this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
      this.loadingMore = false;
    },
    showDetails(data) {
      this.modalDetails.show = true;
      this.modalDetails.data = data;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

#message-detail {
  strong {
    font-weight: 600;
  }
  .message-detail-content {
    background-color: $gray-color-200;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-lg;
    margin: $layout-spacing-lg 0;
  }
  .message-detail-header {
    font-weight: 600;
    margin-bottom: $layout-spacing-lg;
  }
  .message-detail-body {
    white-space: pre-wrap;
  }
  .message-detail-footer {
    color: $gray-color-dark;
    margin-top: $layout-spacing-lg;
  }
}
</style>
