export const APPROVED = 'approved';
export const PENDING = 'pending';
export const REJECTED = 'rejected';
export const IN_APPEAL = 'in_appeal';
export const PENDING_DELETION = 'pending_deletion';
export const DELETED = 'deleted';
export const DISABLED = 'disabled';
export const PAUSED = 'paused';
export const LIMIT_EXCEEDED = 'limit_exceeded';

export const statuses = {
  [APPROVED]: 'Aprovado',
  [PENDING]: 'Pendente',
  [REJECTED]: 'Rejeitado',
  [IN_APPEAL]: 'Em apelo',
  [PENDING_DELETION]: 'Pendente de exclusão',
  [DELETED]: 'Excluído',
  [DISABLED]: 'Desativado',
  [PAUSED]: 'Pausado',
  [LIMIT_EXCEEDED]: 'Limite excedido',
};

export function getName(key) {
  return key in statuses ? statuses[key] : key;
}

export default statuses;
