<template>
  <div class="page-container message-page">
    <portal to="page-name">Mensagens</portal>

    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Mensagens</h1>
      </div>

      <div class="card-body">
        <st-tabs>
          <st-tab name="Histórico">
            <message-list />
          </st-tab>
          <st-tab name="Modelos">
            <template-list />
          </st-tab>
          <st-tab name="Configurações">
            <communication-tab
              :loading="loading"
              :settings="settings"
            ></communication-tab>
          </st-tab>
        </st-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MANAGE_APPOINTMENT_SETTINGS } from '@/data/actions/modules/clinical';
import MessageList from './MessageList.vue';
import TemplateList from './TemplateList.vue';
import CommunicationTab from '../../setting/components/tabs/Communication.vue';

export default {
  components: {
    MessageList,
    TemplateList,
    CommunicationTab,
  },
  data() {
    return {
      loading: false,
      settings: null,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters(['branchSettings']),
    hasAccess() {
      return this.$can(MANAGE_APPOINTMENT_SETTINGS);
    },
  },
  methods: {
    async load() {
      if (!this.hasAccess) return;

      this.loading = true;
      try {
        const { data } = await this.$http.get('/facility-settings');
        this.settings = data;
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
</style>
