export const QUEUED = 'queued';
export const SENDING = 'sending';
export const SENT = 'sent';
export const FAILED = 'failed';
export const DELIVERED = 'delivered';
export const UNDELIVERED = 'undelivered';
export const RECEIVED = 'received';

export const statuses = {
  [QUEUED]: 'Em fila',
  [SENDING]: 'Enviando',
  [SENT]: 'Enviada',
  [FAILED]: 'Falhou',
  [DELIVERED]: 'Entregue',
  [UNDELIVERED]: 'Não entregue',
  [RECEIVED]: 'Recebida',
};

export function getName(key) {
  return key in statuses ? statuses[key] : key;
}

export default statuses;
