export const WHATSAPP = 'whatsapp';
export const EMAIL = 'email';

export const channels = {
  [WHATSAPP]: 'WhatsApp',
  [EMAIL]: 'Email',
};

export function getName(key) {
  return key in channels ? channels[key] : key;
}

export default channels;
