<template>
  <div class="page-container message-template-page">
    <portal to="page-name">Modelos de mensagem</portal>

    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Modelos de mensagem</h1>
      </div>

      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <label for="type" class="form-label">Tipo</label>
            <select id="type" class="form-select" v-model="filter.type">
              <option value="">Todos os tipos</option>
              <option
                v-for="(text, value) in types"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
          </div>
          <div class="column form-group">
            <label for="channel" class="form-label">Canal</label>
            <select id="channel" class="form-select" v-model="filter.channel">
              <option value="">Todos os canais</option>
              <option
                v-for="(text, value) in channels"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
          </div>
          <div class="column form-group">
            <label for="category" class="form-label">Categoria</label>
            <select id="category" class="form-select" v-model="filter.category">
              <option value="">Todas as categorias</option>
              <option
                v-for="(text, value) in categories"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
          </div>
          <div class="column form-group">
            <label for="status" class="form-label">Status</label>
            <select id="status" class="form-select" v-model="filter.status">
              <option value="">Todos os status</option>
              <option
                v-for="(text, value) in statuses"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
          </div>
          <div class="column col-auto">
            <label class="form-label">&nbsp;</label>
            <router-link
              class="btn btn-primary btn-icon btn-icon-left"
              to="/message-templates/create"
            ><fa-icon :icon="['fal', 'plus']"/> Novo template</router-link>
          </div>
        </div>

        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Templates</p>
              <p class="empty-subtitle">
                Nenhum template encontrado. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th class="hide-sm" style="width:40px">#</th>
                <th>Tipo</th>
                <th>Versão</th>
                <th>Canal</th>
                <th>Categoria</th>
                <th>Status</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in data.items" :key="i">
                  <td class="hide-sm">{{ i + data.offset + 1 }}</td>
                  <td>
                    <router-link :to="editLink(item)">{{ item.typeName }}</router-link>
                  </td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.channelName }}</td>
                  <td>{{ item.categoryName }}</td>
                  <td>
                    <span
                      class="label label-rounded"
                      :class="item.statusLabelClass"
                    >{{ item.statusName }}</span>
                  </td>
                  <td class="text-right">
                    <button
                      class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                      @click="edit(item)"
                      data-tooltip="Editar"
                    ><fa-icon :icon="['fal', 'pencil']"></fa-icon></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </template>
      </div>

      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
  </div>
</template>

<script>
import { types, getName as getTypeName } from '@/data/message-types';
import { channels, getName as getChannelName } from '@/data/message-channels';
import { categories, getName as getCategoryName } from '@/data/message-template-categories';
import { statuses, getName as getStatusName } from '@/data/message-template-statuses';
import { statusLabelClass } from './helpers';

export default {
  data() {
    return {
      debounceTimeout: null,
      loading: false,
      filter: {
        type: '',
        channel: '',
        category: '',
        status: '',
      },
      loadingMore: false,
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
      types,
      channels,
      categories,
      statuses,
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    async load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          params[key] = this.filter[key];
        }
      });

      try {
        const { data } = await this.$http.get('/message-templates', { params });

        data.items = data.items.map((item) => {
          item.channelName = getChannelName(item.channel);
          item.typeName = getTypeName(item.type);
          item.categoryName = getCategoryName(item.category);
          item.statusName = getStatusName(item.status);
          item.statusLabelClass = statusLabelClass(item.status);
          return item;
        });

        this.data.hasMore = data.hasMore;
        this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
      this.loadingMore = false;
    },
    edit(item) {
      this.$router.push(this.editLink(item));
    },
    editLink(item) {
      return `/message-templates/${item.id}/edit`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
</style>
